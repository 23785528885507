import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true
})
export class FilterPipe implements PipeTransform {

  public transform(arr: any[] = [], property: string, condition?: boolean | number | string, equal = true): any[] {
    if (condition !== undefined && ['string', 'number', 'boolean'].includes(typeof condition)) return arr.filter(x => equal ? x[property] === condition : x[property] !== condition);
    else return arr.filter(x => !!x[property]);
  }
}
